import React from "react";
import { FaYoutube, FaFacebook, FaExternalLinkAlt } from "react-icons/fa";

const links = [
  {
    title: "Tutorial para procedimiento de autodepósito de tesis / trabajos de graduación EFPEM",
    url: "https://www.canva.com/design/DAGNBW35xco/A1WVC0AeVebqwlHDBc1xkg/watch",
  },
  {
    title: "Tutorial para generar la solvencia de biblioteca EFPEM - Estudiantes",
    youtube: "https://youtu.be/-ZkiYFifg6M?si=nZo2xxSif8z3OCUH",
    fb: "https://www.facebook.com/share/v/16BtXPZY2v/",
  },
  {
    title: "Tutorial para generar la solvencia de biblioteca EFPEM - Trabajador",
    youtube: "https://youtu.be/d6aaGVS4DNw?si=fgwmPLFGw9-x6oB4",
    fb: "https://www.facebook.com/share/v/14xQ6e6L2v/",
  },
  {
    title: "eLibro disponible en Biblioteca Central USAC",
    url: "https://www.canva.com/design/DAGgtkHOwhs/yrM2uP492IA_oyKy14iRKg/watch",
  },
  {
    title: "Cómo utilizar la Biblioteca Digital - Full Training (eLibro)",
    youtube: "https://youtu.be/XF9IDM0ITmE?si=LrjIbv68ixnWxfYI",
  },
];

const Tutorias = () => {
  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4"> Tutoriales (tesis/solvencias) y enlaces de interés</h2>

      <div className="row">
        {links.map((item, index) => (
          <div key={index} className="col-md-6 mb-4">
            <div className="card shadow-sm h-100">
              <div className="card-body">
                <h5 className="card-title">{item.title}</h5>
                <div className="d-flex justify-content-start mt-3">
                  {item.url && (
                    <a
                      href={item.url}
                      className="btn btn-primary me-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaExternalLinkAlt className="me-1" /> Ver enlace
                    </a>
                  )}
                  {item.youtube && (
                    <a
                      href={item.youtube}
                      className="btn btn-danger me-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube className="me-1" /> YouTube
                    </a>
                  )}
                  {item.fb && (
                    <a
                      href={item.fb}
                      className="btn btn-primary"
                      style={{ backgroundColor: "#1877F2", borderColor: "#1877F2" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="me-1" /> Facebook
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tutorias;
